<template>
  <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.siteName"
      label="Site Name"
      prepend-icon="mdi-map-marker"
      disabled
    >
    </v-text-field>
    <v-text-field
      v-model="form.add1"
      label="Address Line 1"
      prepend-icon="mdi-map-marker"
      disabled
    ></v-text-field>
    <v-text-field
      v-model="form.add2"
      label="Address Line 2"
      prepend-icon="mdi-map-marker"
      disabled
    ></v-text-field>
    <v-text-field
      v-model="form.town"
      label="Town/City"
      prepend-icon="mdi-map-marker"
      disabled
    ></v-text-field>
    <v-text-field
      v-model="form.county"
      prepend-icon="mdi-map-marker"
      label="County"
      disabled
    ></v-text-field>
    <v-text-field
      v-model="form.postcode"
      label="Postcode"
      prepend-icon="mdi-map-marker"
      disabled
    ></v-text-field>
    <v-select
      v-model="selectedAddress"
      item-text="value"
      item-value="id"
      :items="form.changeContact"
      prepend-icon="mdi-map-marker"
      label="Change Primary Address"
    ></v-select>

    <v-btn depressed color="blue" @click="validate" block dark large>
      Save changes
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        siteName: "Site Name",
        add1: "add 1",
        add2: "add 2",
        town: "Town",
        county: "County",
        postcode: "M12 6JH",
        changeContact: [
          "Site Name",
          "Renown House",
          "Renown House 2",
          "Renown House 3"
        ]
      },
      selectedAddress: "Site Name"
    }
  }
}
</script>
